var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-properties" },
    [
      _vm.report
        ? _c(
            "b-tabs",
            [
              _c("b-tab", { attrs: { title: "Run" } }, [
                _vm.report
                  ? _c("div", [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.runReport.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "flex-row justify-content-center"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "icon-preview",
                                    style: "color: " + _vm.reportIconColor + ";"
                                  },
                                  [_c("i", { class: _vm.previewIconValue })]
                                ),
                                _c("h3", [_vm._v(_vm._s(_vm.reportName))])
                              ]
                            )
                          ]),
                          _vm.requiresAnyParametersToBeSelected
                            ? _c("div", [
                                _c("p", [
                                  _c("i", { staticClass: "fa fa-info-circle" }),
                                  _vm._v(
                                    " This report requires at least one parameter to be selected. "
                                  )
                                ])
                              ])
                            : _vm._e(),
                          !_vm.currentDateGroupingType
                            ? _c(
                                "div",
                                { staticClass: "position-relative z-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "green-label mt-1" },
                                    [_vm._v("Date Grouping Mode:")]
                                  ),
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-select", {
                                        staticClass: "text-input",
                                        attrs: {
                                          options:
                                            _vm.dateGroupingCurrentOptions,
                                          required: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.reportParamChange()
                                          }
                                        },
                                        model: {
                                          value: _vm.currentDateGroupingType,
                                          callback: function($$v) {
                                            _vm.currentDateGroupingType = $$v
                                          },
                                          expression: "currentDateGroupingType"
                                        }
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { staticClass: "align-items-center" },
                                        [
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName: "v-b-toggle"
                                              }
                                            ],
                                            staticClass: "fa fa-info-circle",
                                            attrs: {
                                              title: _vm.dateGroupingModeTooltip
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.currentDateMode
                            ? _c(
                                "div",
                                { staticClass: "position-relative z-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "green-label mt-1" },
                                    [_vm._v("Date Mode:")]
                                  ),
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-select", {
                                        staticClass: "text-input",
                                        attrs: {
                                          options: _vm.dateModeCurrentOptions,
                                          required: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.reportParamChange()
                                          }
                                        },
                                        model: {
                                          value: _vm.currentDateMode,
                                          callback: function($$v) {
                                            _vm.currentDateMode = $$v
                                          },
                                          expression: "currentDateMode"
                                        }
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { staticClass: "align-items-center" },
                                        [
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName: "v-b-toggle"
                                              }
                                            ],
                                            staticClass: "fa fa-info-circle",
                                            attrs: {
                                              title:
                                                "Set which dates the report will display"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.currentTimezone
                            ? _c(
                                "div",
                                { staticClass: "position-relative z-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "green-label mt-1" },
                                    [_vm._v("Timezone:")]
                                  ),
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-select", {
                                        staticClass: "text-input",
                                        attrs: {
                                          options: _vm.timezoneOptions,
                                          required: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.reportParamChange()
                                          }
                                        },
                                        model: {
                                          value: _vm.currentTimezone,
                                          callback: function($$v) {
                                            _vm.currentTimezone = $$v
                                          },
                                          expression: "currentTimezone"
                                        }
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { staticClass: "align-items-center" },
                                        [
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName: "v-b-toggle"
                                              }
                                            ],
                                            staticClass: "fa fa-info-circle",
                                            attrs: {
                                              title:
                                                "Set which timezone the report will be displayed in"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("device-selection", {
                            attrs: {
                              "enable-device-selection":
                                _vm.enableDeviceSelection,
                              "current-device-selection-mode":
                                _vm.currentDeviceSelectionMode,
                              "current-device-selection-value":
                                _vm.currentDeviceSelectionValue,
                              "device-selection-mode-options":
                                _vm.deviceSelectionModeOptions,
                              "default-device-selection-value":
                                _vm.currentDeviceSelectionValue,
                              "device-list": _vm.deviceList
                            },
                            on: { "device-change": _vm.setDeviceData }
                          }),
                          _c("div"),
                          _vm.currentDateMode === "between-dates" &&
                          !_vm.requiresDatesSelected
                            ? _c(
                                "div",
                                { staticClass: "position-relative z-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "green-label mt-1" },
                                    [_vm._v("Start Date:")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.currentStartDateValue,
                                        expression: "currentStartDateValue"
                                      }
                                    ],
                                    staticClass:
                                      "text-input date-picker z-2 dropdown",
                                    attrs: { type: "date" },
                                    domProps: {
                                      value: _vm.currentStartDateValue
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reportParamChange()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.currentStartDateValue =
                                          $event.target.value
                                      }
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm.currentDateMode === "between-dates" &&
                          !_vm.requiresDatesSelected
                            ? _c(
                                "div",
                                { staticClass: "position-relative z-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "green-label mt-1" },
                                    [_vm._v("End Date:")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.currentEndDateValue,
                                        expression: "currentEndDateValue"
                                      }
                                    ],
                                    staticClass:
                                      "text-input date-picker z-2 dropdown",
                                    attrs: { type: "date" },
                                    domProps: {
                                      value: _vm.currentEndDateValue
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reportParamChange()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.currentEndDateValue =
                                          $event.target.value
                                      }
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "modal-footer" }, [
                            _vm.anyDirty
                              ? _c(
                                  "button",
                                  { on: { click: _vm.saveReportProps } },
                                  [_vm._v("Save")]
                                )
                              : _vm._e(),
                            _c("button", { attrs: { type: "submit" } }, [
                              _vm._v("Run Report")
                            ]),
                            _c("button", { on: { click: _vm.hideModal } }, [
                              _vm._v("Cancel")
                            ])
                          ])
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _c("b-tab", { attrs: { title: "Run Options" } }, [
                _vm.report
                  ? _c("div", [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.runReport.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "position-relative z-2" },
                            [
                              _c("label", { staticClass: "green-label mt-1" }, [
                                _vm._v("Date Grouping Mode:")
                              ]),
                              _c(
                                "b-input-group",
                                [
                                  _c("b-select", {
                                    staticClass: "text-input",
                                    attrs: {
                                      options: _vm.dateGroupingCurrentOptions,
                                      required: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reportParamChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.currentDateGroupingType,
                                      callback: function($$v) {
                                        _vm.currentDateGroupingType = $$v
                                      },
                                      expression: "currentDateGroupingType"
                                    }
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    { staticClass: "align-items-center" },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle"
                                          }
                                        ],
                                        staticClass: "fa fa-info-circle",
                                        attrs: {
                                          title: _vm.dateGroupingModeTooltip
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "position-relative z-2" },
                            [
                              _c("label", { staticClass: "green-label mt-1" }, [
                                _vm._v("Date Mode:")
                              ]),
                              _c(
                                "b-input-group",
                                [
                                  _c("b-select", {
                                    staticClass: "text-input",
                                    attrs: {
                                      options: _vm.dateModeCurrentOptions,
                                      required: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reportParamChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.currentDateMode,
                                      callback: function($$v) {
                                        _vm.currentDateMode = $$v
                                      },
                                      expression: "currentDateMode"
                                    }
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    { staticClass: "align-items-center" },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle"
                                          }
                                        ],
                                        staticClass: "fa fa-info-circle",
                                        attrs: {
                                          title:
                                            "Set which dates the report will display"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "position-relative z-2" },
                            [
                              _c("label", { staticClass: "green-label mt-1" }, [
                                _vm._v("Timezone:")
                              ]),
                              _c(
                                "b-input-group",
                                [
                                  _c("b-select", {
                                    staticClass: "text-input",
                                    attrs: {
                                      options: _vm.timezoneOptions,
                                      required: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reportParamChange()
                                      }
                                    },
                                    model: {
                                      value: _vm.currentTimezone,
                                      callback: function($$v) {
                                        _vm.currentTimezone = $$v
                                      },
                                      expression: "currentTimezone"
                                    }
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    { staticClass: "align-items-center" },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle"
                                          }
                                        ],
                                        staticClass: "fa fa-info-circle",
                                        attrs: {
                                          title:
                                            "Set which timezone the report will be displayed in"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("device-selection", {
                            attrs: {
                              "enable-device-selection":
                                _vm.enableDeviceSelection,
                              "current-device-selection-mode":
                                _vm.currentDeviceSelectionMode,
                              "current-device-selection-value":
                                _vm.currentDeviceSelectionValue,
                              "device-selection-mode-options":
                                _vm.deviceSelectionModeOptions,
                              "default-device-selection-value":
                                _vm.currentDeviceSelectionValue,
                              "device-list": _vm.deviceList
                            },
                            on: { "device-change": _vm.setDeviceData }
                          }),
                          _c("div"),
                          _vm.currentDateMode === "between-dates"
                            ? _c(
                                "div",
                                { staticClass: "position-relative z-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "green-label mt-1" },
                                    [_vm._v("Start Date:")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.currentStartDateValue,
                                        expression: "currentStartDateValue"
                                      }
                                    ],
                                    staticClass:
                                      "text-input date-picker z-2 dropdown",
                                    attrs: { type: "date" },
                                    domProps: {
                                      value: _vm.currentStartDateValue
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reportParamChange()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.currentStartDateValue =
                                          $event.target.value
                                      }
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm.currentDateMode === "between-dates"
                            ? _c(
                                "div",
                                { staticClass: "position-relative z-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "green-label mt-1" },
                                    [_vm._v("End Date:")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.currentEndDateValue,
                                        expression: "currentEndDateValue"
                                      }
                                    ],
                                    staticClass:
                                      "text-input date-picker z-2 dropdown",
                                    attrs: { type: "date" },
                                    domProps: {
                                      value: _vm.currentEndDateValue
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.reportParamChange()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.currentEndDateValue =
                                          $event.target.value
                                      }
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "modal-footer" }, [
                            _vm.anyDirty
                              ? _c(
                                  "button",
                                  { on: { click: _vm.saveReportProps } },
                                  [_vm._v("Save")]
                                )
                              : _vm._e(),
                            _c("button", { attrs: { type: "submit" } }, [
                              _vm._v("Run Report")
                            ]),
                            _c("button", { on: { click: _vm.hideModal } }, [
                              _vm._v("Cancel")
                            ])
                          ])
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.enableEditTabs
                ? _c("b-tab", { attrs: { title: "Edit Defaults" } }, [
                    _vm.report
                      ? _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.saveDefaultParameters.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c("div", [
                              _c("h3", [_vm._v("Report Default Parameters")]),
                              _c("p", { staticClass: "mt-1" }, [
                                _c("i", { staticClass: "fa fa-info-circle" }),
                                _vm._v(
                                  " Default Parameters determine how a report is configured when you first open it. If you want to change the defaults on a report you do not own, you can create a copy of it and then change the defaults. If a value is not set with a default, users have to select a value before they can run the report. "
                                )
                              ])
                            ]),
                            _vm.report && _vm.report.default_parameters
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "position-relative z-2" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "green-label mt-1" },
                                          [
                                            _vm._v(
                                              "Default Date Grouping Mode:"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-select", {
                                              staticClass: "text-input",
                                              attrs: {
                                                options:
                                                  _vm.dateGroupingDefaultOptions,
                                                disabled: !_vm.enableEdit
                                              },
                                              model: {
                                                value:
                                                  _vm.defaultDateGroupingType,
                                                callback: function($$v) {
                                                  _vm.defaultDateGroupingType = $$v
                                                },
                                                expression:
                                                  "defaultDateGroupingType"
                                              }
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              {
                                                staticClass:
                                                  "align-items-center"
                                              },
                                              [
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName: "v-b-toggle"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "fa fa-info-circle",
                                                  attrs: {
                                                    title: _vm.dateModeTooltip
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "position-relative z-2" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "green-label mt-1" },
                                          [_vm._v("Default Date Mode:")]
                                        ),
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-select", {
                                              staticClass: "text-input",
                                              attrs: {
                                                options:
                                                  _vm.dateModeDefaultOptions,
                                                disabled: !_vm.enableEdit
                                              },
                                              model: {
                                                value: _vm.defaultDateMode,
                                                callback: function($$v) {
                                                  _vm.defaultDateMode = $$v
                                                },
                                                expression: "defaultDateMode"
                                              }
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              {
                                                staticClass:
                                                  "align-items-center"
                                              },
                                              [
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName: "v-b-toggle"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "fa fa-info-circle",
                                                  attrs: {
                                                    title: _vm.dateModeTooltip
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.defaultDateModeText
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 text-center font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              " Example: " +
                                                _vm._s(
                                                  _vm.defaultDateModeText
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.defaultDateError
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-center text-danger"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.defaultDateError) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.defaultDateMode === "between-dates"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "position-relative z-2"
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "green-label mt-1"
                                              },
                                              [_vm._v("Default Start Date:")]
                                            ),
                                            _c("b-select", {
                                              staticClass: "text-input",
                                              attrs: {
                                                options: _vm.dateTypeOptions,
                                                disabled: !_vm.enableEdit
                                              },
                                              on: {
                                                change: function($event) {
                                                  _vm.defaultStartDateValue = null
                                                }
                                              },
                                              model: {
                                                value: _vm.defaultStartDateType,
                                                callback: function($$v) {
                                                  _vm.defaultStartDateType = $$v
                                                },
                                                expression:
                                                  "defaultStartDateType"
                                              }
                                            }),
                                            _vm.defaultStartDateType ===
                                            "fixed-date"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.defaultStartDateValue,
                                                      expression:
                                                        "defaultStartDateValue"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-input date-picker z-2 dropdown",
                                                  attrs: {
                                                    disabled: !_vm.enableEdit,
                                                    type: "date"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.defaultStartDateValue
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.defaultStartDateValue =
                                                        $event.target.value
                                                    }
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.defaultStartDateType ===
                                            "relative-past-days"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.defaultStartDateValue,
                                                      expression:
                                                        "defaultStartDateValue"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-input date-picker z-2 dropdown",
                                                  attrs: {
                                                    disabled: !_vm.enableEdit,
                                                    type: "number"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.defaultStartDateValue
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.defaultStartDateValue =
                                                        $event.target.value
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.defaultDateMode === "between-dates"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "position-relative z-2"
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "green-label mt-1"
                                              },
                                              [_vm._v("Default End Date:")]
                                            ),
                                            _c("b-select", {
                                              staticClass: "text-input",
                                              attrs: {
                                                options: _vm.dateTypeOptions,
                                                disabled: !_vm.enableEdit
                                              },
                                              on: {
                                                change: function($event) {
                                                  _vm.defaultEndDateValue = null
                                                }
                                              },
                                              model: {
                                                value: _vm.defaultEndDateType,
                                                callback: function($$v) {
                                                  _vm.defaultEndDateType = $$v
                                                },
                                                expression: "defaultEndDateType"
                                              }
                                            }),
                                            _vm.defaultEndDateType ===
                                            "fixed-date"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.defaultEndDateValue,
                                                      expression:
                                                        "defaultEndDateValue"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-input date-picker z-2 dropdown",
                                                  attrs: {
                                                    disabled: !_vm.enableEdit,
                                                    type: "date"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.defaultEndDateValue
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.defaultEndDateValue =
                                                        $event.target.value
                                                    }
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.defaultEndDateType ===
                                            "relative-past-days"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.defaultEndDateValue,
                                                      expression:
                                                        "defaultEndDateValue"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-input date-picker z-2 dropdown",
                                                  attrs: {
                                                    disabled: !_vm.enableEdit,
                                                    type: "number"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.defaultEndDateValue
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.defaultEndDateValue =
                                                        $event.target.value
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("device-selection", {
                                      attrs: {
                                        "enable-device-selection":
                                          _vm.enableDeviceSelection,
                                        "current-device-selection-mode":
                                          _vm.defaultDeviceSelectionMode,
                                        "current-device-selection-value":
                                          _vm.defaultDeviceSelectionValue,
                                        "device-selection-mode-options":
                                          _vm.deviceSelectionModeOptions,
                                        "default-device-selection-value":
                                          _vm.defaultDeviceSelectionValue,
                                        "device-list": _vm.deviceList
                                      },
                                      on: {
                                        "device-change":
                                          _vm.setDeviceDefaultData
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "modal-footer" }, [
                              _c(
                                "button",
                                {
                                  attrs: {
                                    type: "submit",
                                    disabled: !_vm.enableEdit
                                  }
                                },
                                [_vm._v("Save Defaults")]
                              ),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$bvModal.hide(_vm.modalId)
                                    }
                                  }
                                },
                                [_vm._v("Cancel")]
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.isAdmin && _vm.enableEditTabs
                ? _c("b-tab", { attrs: { title: "Admin" } }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.saveAdminOptions.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("div", [
                          _c("label", { staticClass: "green-label mt-1" }, [
                            _vm._v("Report Name:")
                          ]),
                          _c("div", { staticClass: "flex-row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon-preview",
                                style: "color: " + _vm.reportIconColor + ";"
                              },
                              [_c("i", { class: _vm.previewIconValue })]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reportName,
                                  expression: "reportName"
                                }
                              ],
                              ref: "reportNameField",
                              staticClass: "flex-grow-1",
                              attrs: {
                                placeholder: "Report Name",
                                disabled: !_vm.enableEdit
                              },
                              domProps: { value: _vm.reportName },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.reportName = $event.target.value
                                }
                              }
                            })
                          ])
                        ]),
                        _vm.enableEdit
                          ? _c("div", {}, [
                              _c("label", { staticClass: "green-label mt-1" }, [
                                _vm._v("Report Icon:")
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-row" },
                                [
                                  _c("IconSelector", {
                                    staticClass: "flex-grow-1",
                                    attrs: {
                                      disabled: !_vm.enableEdit,
                                      color: _vm.reportColor
                                    },
                                    model: {
                                      value: _vm.reportIcon,
                                      callback: function($$v) {
                                        _vm.reportIcon = $$v
                                      },
                                      expression: "reportIcon"
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportColor,
                                        expression: "reportColor"
                                      }
                                    ],
                                    staticClass: "w-25",
                                    attrs: {
                                      type: "color",
                                      disabled: !_vm.enableEdit,
                                      maxlength: "256",
                                      name: "owner",
                                      "data-name": "owner",
                                      placeholder: "Color",
                                      id: "owner_id"
                                    },
                                    domProps: { value: _vm.reportColor },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.reportColor = $event.target.value
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c("label", { staticClass: "green-label mt-1" }, [
                              _vm._v("Report Visibility:")
                            ]),
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "text-input",
                                model: {
                                  value: _vm.isPublic,
                                  callback: function($$v) {
                                    _vm.isPublic = $$v
                                  },
                                  expression: "isPublic"
                                }
                              },
                              [_vm._v("Is Public?")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("label", { staticClass: "green-label mt-1" }, [
                              _vm._v("Report Owner:")
                            ]),
                            _c("b-select", {
                              staticClass: "text-input",
                              attrs: {
                                placeholder: "Report Owner",
                                options: _vm.reportOwnerOptions,
                                disabled: !_vm.enableEdit
                              },
                              model: {
                                value: _vm.reportOwner,
                                callback: function($$v) {
                                  _vm.reportOwner = $$v
                                },
                                expression: "reportOwner"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("div", [
                              _c("strong", [_vm._v("Note:")]),
                              _vm._v(
                                " The Below applies a 'related_type' filter to the entire report. This should be set to 'none' for system-wide statistics (e.g User Volumes, etc). For safety if nothing is provided the system defaults to supplying a list of the user's devices for related_ids and this filter breaks that. "
                              )
                            ]),
                            _c("label", { staticClass: "green-label mt-1" }, [
                              _vm._v("Related Type:")
                            ]),
                            _c("b-select", {
                              staticClass: "text-input",
                              attrs: {
                                placeholder: "Related Type",
                                options: _vm.relatedTypeOptions,
                                disabled: !_vm.enableEdit
                              },
                              model: {
                                value: _vm.relatedType,
                                callback: function($$v) {
                                  _vm.relatedType = $$v
                                },
                                expression: "relatedType"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("label", { staticClass: "green-label mt-1" }, [
                              _vm._v("Related Id Selection:")
                            ]),
                            _c("b-select", {
                              staticClass: "text-input",
                              attrs: {
                                placeholder: "Report Owner",
                                options: _vm.deviceSelectModeOptions,
                                disabled: !_vm.enableEdit
                              },
                              model: {
                                value: _vm.deviceSelectMode,
                                callback: function($$v) {
                                  _vm.deviceSelectMode = $$v
                                },
                                expression: "deviceSelectMode"
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              attrs: {
                                type: "submit",
                                disabled: !_vm.enableEdit
                              }
                            },
                            [_vm._v("Save")]
                          ),
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.hide(_vm.modalId)
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          )
                        ])
                      ]
                    ),
                    _vm._v(
                      " Parent Report: " + _vm._s(_vm.report.parent_name) + " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }