<template>
<div class="report-properties">
  <b-tabs v-if="report">
    <b-tab title="Run">
      <div v-if="report">
        <form v-on:submit.prevent="runReport">
          <div>
            <div class="flex-row justify-content-center">
              <div class="icon-preview" :style="'color: ' + reportIconColor + ';'">
                <i :class="previewIconValue"></i>
              </div>
              <h3>{{reportName}}</h3>
            </div>
          </div>
          <div v-if="requiresAnyParametersToBeSelected">
            <p>
              <i class="fa fa-info-circle"></i> This report requires at least one parameter to be selected.
            </p>
          </div>
          <div class="position-relative z-2"  v-if="!currentDateGroupingType">
            <label class="green-label mt-1">Date Grouping Mode:</label>
            <b-input-group>
              <b-select class="text-input" @change="reportParamChange()"
                        :options="dateGroupingCurrentOptions" v-model="currentDateGroupingType" required/>
              <b-input-group-append class="align-items-center">
                <i class="fa fa-info-circle" v-b-toggle :title="dateGroupingModeTooltip"></i>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="position-relative z-2" v-if="!currentDateMode">
            <label class="green-label mt-1">Date Mode:</label>
            <b-input-group>
              <b-select class="text-input" @change="reportParamChange()"
                        :options="dateModeCurrentOptions" v-model="currentDateMode" required/>
              <b-input-group-append class="align-items-center">
                <i class="fa fa-info-circle" v-b-toggle title="Set which dates the report will display"></i>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="position-relative z-2" v-if="!currentTimezone">
            <label class="green-label mt-1">Timezone:</label>
            <b-input-group>
              <b-select class="text-input" @change="reportParamChange()"
                        :options="timezoneOptions" v-model="currentTimezone" required/>
              <b-input-group-append class="align-items-center">
                <i class="fa fa-info-circle" v-b-toggle title="Set which timezone the report will be displayed in"></i>
              </b-input-group-append>
            </b-input-group>
          </div>
          <device-selection
            :enable-device-selection="enableDeviceSelection"
            :current-device-selection-mode="currentDeviceSelectionMode"
            :current-device-selection-value="currentDeviceSelectionValue"
            :device-selection-mode-options="deviceSelectionModeOptions"
            :default-device-selection-value="currentDeviceSelectionValue"
            :device-list="deviceList" @device-change="setDeviceData"/>
          <div>
            <!--            {{currentDateModeText}}-->
          </div>
          <div class="position-relative z-2" v-if="currentDateMode==='between-dates' && !requiresDatesSelected">
            <label class="green-label mt-1">Start Date:</label>
            <input type="date" class="text-input date-picker z-2 dropdown" @change="reportParamChange()" v-model="currentStartDateValue">
          </div>
          <div class="position-relative z-2" v-if="currentDateMode==='between-dates' && !requiresDatesSelected">
            <label class="green-label mt-1">End Date:</label>
            <input type="date" class="text-input date-picker z-2 dropdown" @change="reportParamChange()" v-model="currentEndDateValue">
          </div>
          <div class="modal-footer">
            <button class="" @click="saveReportProps"
                    v-if="anyDirty">Save</button>
            <button class="" type="submit">Run Report</button>
            <button class="" @click="hideModal">Cancel</button>
          </div>
        </form>
      </div>
    </b-tab>
    <b-tab title="Run Options">
      <div v-if="report">
        <form v-on:submit.prevent="runReport">
          <div class="position-relative z-2">
            <label class="green-label mt-1">Date Grouping Mode:</label>
            <b-input-group>
              <b-select class="text-input" @change="reportParamChange()"
                        :options="dateGroupingCurrentOptions" v-model="currentDateGroupingType" required/>
              <b-input-group-append class="align-items-center">
                <i class="fa fa-info-circle" v-b-toggle :title="dateGroupingModeTooltip"></i>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="position-relative z-2">
            <label class="green-label mt-1">Date Mode:</label>
            <b-input-group>
              <b-select class="text-input" @change="reportParamChange()"
                        :options="dateModeCurrentOptions" v-model="currentDateMode" required/>
              <b-input-group-append class="align-items-center">
                <i class="fa fa-info-circle" v-b-toggle title="Set which dates the report will display"></i>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="position-relative z-2">
            <label class="green-label mt-1">Timezone:</label>
            <b-input-group>
              <b-select class="text-input" @change="reportParamChange()"
                        :options="timezoneOptions" v-model="currentTimezone" required/>
              <b-input-group-append class="align-items-center">
                <i class="fa fa-info-circle" v-b-toggle title="Set which timezone the report will be displayed in"></i>
              </b-input-group-append>
            </b-input-group>
          </div>
          <device-selection
            :enable-device-selection="enableDeviceSelection"
            :current-device-selection-mode="currentDeviceSelectionMode"
            :current-device-selection-value="currentDeviceSelectionValue"
            :device-selection-mode-options="deviceSelectionModeOptions"
            :default-device-selection-value="currentDeviceSelectionValue"
            :device-list="deviceList" @device-change="setDeviceData"/>
          <div>
<!--            {{currentDateModeText}}-->
          </div>
          <div class="position-relative z-2" v-if="currentDateMode==='between-dates'">
            <label class="green-label mt-1">Start Date:</label>
            <input type="date" class="text-input date-picker z-2 dropdown" @change="reportParamChange()" v-model="currentStartDateValue">
          </div>
          <div class="position-relative z-2" v-if="currentDateMode==='between-dates'">
            <label class="green-label mt-1">End Date:</label>
            <input type="date" class="text-input date-picker z-2 dropdown" @change="reportParamChange()" v-model="currentEndDateValue">
          </div>
          <div class="modal-footer">
            <button class="" @click="saveReportProps"
                      v-if="anyDirty">Save</button>
            <button class="" type="submit">Run Report</button>
            <button class="" @click="hideModal">Cancel</button>
          </div>
        </form>
      </div>
    </b-tab>
    <b-tab title="Edit Defaults" v-if="enableEditTabs">
      <form v-if="report" v-on:submit.prevent="saveDefaultParameters">
        <div>
          <h3>Report Default Parameters</h3>
          <p class="mt-1"><i class="fa fa-info-circle"></i> Default Parameters determine how a report is configured when you first
            open it. If you want to change the defaults on a report you do not own, you can create a copy of it and then
            change the defaults.
            If a value is not set with a default, users have to select a value before they can run the report.
          </p>
        </div>
        <div v-if="report && report.default_parameters">
          <div class="position-relative z-2">
            <label class="green-label mt-1">Default Date Grouping Mode:</label>
            <b-input-group>
              <b-select class="text-input" :options="dateGroupingDefaultOptions" v-model="defaultDateGroupingType" :disabled="!enableEdit"/>
              <b-input-group-append class="align-items-center">
                <i class="fa fa-info-circle" v-b-toggle :title="dateModeTooltip"></i>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="position-relative z-2">
            <label class="green-label mt-1">Default Date Mode:</label>
            <b-input-group>
              <b-select class="text-input" :options="dateModeDefaultOptions" v-model="defaultDateMode" :disabled="!enableEdit"/>
              <b-input-group-append class="align-items-center">
                <i class="fa fa-info-circle" v-b-toggle :title="dateModeTooltip"></i>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="mt-2 text-center font-weight-bold" v-if="defaultDateModeText">
            Example: {{ defaultDateModeText }}
          </div>
          <div class="text-center text-danger" v-if="defaultDateError">
            {{defaultDateError}}
          </div>

          <div class="position-relative z-2" v-if="defaultDateMode==='between-dates'">
            <label class="green-label mt-1">Default Start Date:</label>
            <b-select class="text-input" :options="dateTypeOptions" v-model="defaultStartDateType" :disabled="!enableEdit"
                      @change="defaultStartDateValue=null"/>

            <input v-if="defaultStartDateType==='fixed-date'" v-model="defaultStartDateValue" :disabled="!enableEdit"
                   type="date" class="text-input date-picker z-2 dropdown" >

            <input v-if="defaultStartDateType==='relative-past-days'" v-model="defaultStartDateValue" :disabled="!enableEdit"
                   type="number" class="text-input date-picker z-2 dropdown" >
          </div>

          <div class="position-relative z-2" v-if="defaultDateMode==='between-dates'">
            <label class="green-label mt-1">Default End Date:</label>
            <b-select class="text-input" :options="dateTypeOptions" v-model="defaultEndDateType" :disabled="!enableEdit"
                      @change="defaultEndDateValue=null"/>

            <input v-if="defaultEndDateType==='fixed-date'" v-model="defaultEndDateValue" :disabled="!enableEdit"
                   type="date" class="text-input date-picker z-2 dropdown">

            <input v-if="defaultEndDateType==='relative-past-days'" v-model="defaultEndDateValue" :disabled="!enableEdit"
                   type="number" class="text-input date-picker z-2 dropdown" >
          </div>
          <device-selection
            :enable-device-selection="enableDeviceSelection"
            :current-device-selection-mode="defaultDeviceSelectionMode"
            :current-device-selection-value="defaultDeviceSelectionValue"
            :device-selection-mode-options="deviceSelectionModeOptions"
            :default-device-selection-value="defaultDeviceSelectionValue"
            :device-list="deviceList" @device-change="setDeviceDefaultData"/>
        </div>
        <div class="modal-footer">
          <button class="" type="submit" :disabled="!enableEdit">Save Defaults</button>
          <button class="" @click="$bvModal.hide(modalId)">Cancel</button>
        </div>
      </form>
    </b-tab>
    <b-tab title="Admin" v-if="isAdmin && enableEditTabs">
      <form v-on:submit.prevent="saveAdminOptions">
        <div>
          <label class="green-label mt-1">Report Name:</label>
          <div class="flex-row">
            <div class="icon-preview" :style="'color: ' + reportIconColor + ';'">
              <i :class="previewIconValue"></i>
            </div>
            <input class="flex-grow-1" placeholder="Report Name" v-model="reportName" :disabled="!enableEdit"
                   ref="reportNameField">
          </div>
        </div>
        <div class="" v-if="enableEdit">
          <label class="green-label mt-1">Report Icon:</label>
          <div class="flex-row">
            <IconSelector class="flex-grow-1" v-model="reportIcon" :disabled="!enableEdit"  :color="reportColor"></IconSelector>
            <input v-model="reportColor" class="w-25" type="color" :disabled="!enableEdit"
                   maxlength="256" name="owner" data-name="owner" placeholder="Color" id="owner_id">
          </div>
        </div>
        <div>
          <label class="green-label mt-1">Report Visibility:</label>
          <b-form-checkbox class="text-input" v-model="isPublic">Is Public?</b-form-checkbox>
        </div>
        <div>
          <label class="green-label mt-1">Report Owner:</label>
          <b-select class="text-input" placeholder="Report Owner" v-model="reportOwner" :options="reportOwnerOptions" :disabled="!enableEdit" />
        </div>
        <div>
          <div>
            <strong>Note:</strong> The Below applies a 'related_type' filter to the entire report. This should be set to 'none' for system-wide
            statistics (e.g User Volumes, etc). For safety if nothing is provided the system defaults to supplying a list
            of the user's devices for related_ids and this filter breaks that.
          </div>
          <label class="green-label mt-1">Related Type:</label>
          <b-select class="text-input" placeholder="Related Type"
                    v-model="relatedType" :options="relatedTypeOptions" :disabled="!enableEdit" />
        </div>
        <div>
          <label class="green-label mt-1">Related Id Selection:</label>
          <b-select class="text-input" placeholder="Report Owner"
                    v-model="deviceSelectMode" :options="deviceSelectModeOptions" :disabled="!enableEdit" />
        </div>
        <div class="modal-footer">
          <button class="" type="submit" :disabled="!enableEdit">Save</button>
          <button class="" @click="$bvModal.hide(modalId)">Cancel</button>
        </div>
      </form>
      Parent Report: {{report.parent_name}}
    </b-tab>
  </b-tabs>

</div>
</template>

<script>
import * as fillTemplate from 'es6-dynamic-template'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import ReportHelper from '@/components/helpers/ReportHelper'
import * as config from '@/config'
import IconSelector from '@/components/shared/IconSelector.vue'
import DeviceSelection from '@/components/stats/DeviceSelection.vue'

export default {
  name: 'ReportProperties',
  components: {DeviceSelection, IconSelector},
  props: {
    report: Object,
    modalId: String,
    // Whether to show the report editing tabs
    enableEditTabs: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      startDate: null,
      endDate: null,
      userId: null,
      reportName: '',
      reportIcon: null,
      reportColor: null,
      isPublic: null,
      dateModeOptions: [
        {
          value: 'today',
          text: 'Today',
          help: 'Date will be for Today Only.' // Tooltips for the 'i' icon next to the default date mode select box
        },
        {
          value: 'yesterday',
          text: 'Yesterday',
          help: 'Date will be for Yesterday Only.'
        },
        {
          value: 'ytd',
          text: 'Year to Date',
          help: 'Dates will be the first day of the year, through to the current day.'
        },
        {
          value: 'mtd',
          text: 'Month to Date',
          help: 'Dates will be the first day of the month, through to the current day.'
        },
        {
          value: 'wtd',
          text: 'Week to Date',
          help: 'Dates will be the first day of the week, through to the current day.'
        },
        {
          value: 'last-week',
          text: 'Previous Week',
          help: 'Dates will be every day in the previous week.'
        },
        {
          value: 'last-month',
          text: 'Previous Month',
          help: 'Dates will be every day in the previous Month.'
        },
        {
          value: 'last-year',
          text: 'Previous Year',
          help: 'Dates will be every day in the previous Year.'
        },
        {
          value: 'between-dates',
          text: 'Between Two Dates',
          helpText: 'Specify Two Dates, including options to set up a floating reporting window.'
        }
      ],
      dateTypeOptions: [
        {
          value: null,
          text: 'Select a Date Type'
        },
        {
          value: 'today',
          text: 'Today',
          help: `Today`,
          // Below 'compareValue' is a pseudo value used for checking relative dates to ensure stand and end are valid
          compareValue: 0
        },
        {
          value: 'yesterday',
          text: 'Yesterday',
          help: `Yesterday`,
          compareValue: 1
        },
        {
          value: 'start-of-week',
          text: 'Start of this Week',
          help: `First day of the week (Monday)`,
          compareValue: 3
        },
        {
          value: 'end-of-week',
          text: 'End of this Week',
          help: `Last day of the week (Sunday)`,
          compareValue: 2
        },
        {
          value: 'relative-past-days',
          text: 'Relative <N> Days in the Past',
          // eslint-disable-next-line no-template-curly-in-string
          help: '${dateValue} Day(s) ago'
        },
        {
          value: 'fixed-date',
          text: 'Fixed Date',
          // eslint-disable-next-line no-template-curly-in-string
          help: '${dateValue}'
        }
      ],
      dateGroupingOptions: [
        {
          value: 'days',
          text: 'Days'
        },
        {
          value: 'weeks',
          text: 'Weeks'
        },
        {
          value: 'months',
          text: 'Months'
        },
        {
          value: 'years',
          text: 'Years'
        }
      ],

      timezoneOptions: [],
      deviceSelectionSelectAll: false,
      deviceList: [],
      // Defaults
      defaultDateMode: null,
      defaultStartDateType: null,
      defaultStartDateValue: null,
      defaultEndDateType: null,
      defaultEndDateValue: null,
      defaultDateGroupingType: null,
      defaultDeviceSelectionMode: null,
      defaultDeviceSelectionValue: null,
      // Current Report Settings
      currentDateMode: null,
      currentStartDateType: null,
      currentStartDateValue: null,
      currentEndDateType: null,
      currentEndDateValue: null,
      currentDateGroupingType: null,
      currentDeviceSelectionMode: 'all',
      currentDeviceSelectionValue: null,
      currentTimezone: '',
      reportOwner: null,
      isAdmin: null,
      deviceSelectModeOptions: [
        {
          text: 'None',
          value: null
        },
        {
          text: 'Single',
          value: 'single'
        },
        {
          text: 'Multi',
          value: 'multi'
        }
      ],
      deviceSelectMode: null,
      relatedTypeOptions: [
        {
          text: '<Not Specified>',
          value: null
        },
        'none',
        'device',
        'user'
      ],
      relatedType: null,
    }
  },
  async mounted () {
    let userData = await this.$auth.getUserData()
    this.isAdmin = await this.$auth.roleIn(config.roles.admin)
    this.userId = userData.user_id
    this.reportName = this.report.name
    this.reportIcon = this.report.icon
    this.reportColor = this.report.color
    this.reportOwner = this.report.owner_id
    this.isPublic = this.report.is_public

    this.deviceSelectMode = this.getReportOption('device_selection_mode')
    this.relatedType = this.getReportOption('related_type')

    // Populate the TZ list from available zones in the local system
    this.timezoneOptions = Intl.supportedValuesOf('timeZone')
    // This *should* be the user's current timezone.
    this.currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (this.report.default_parameters === null) {
      this.report.default_parameters = {}
    } else {
      this.loadCurrentsFromDefaultsObj(this.report.default_parameters)
      this.loadDefaultsFromDefaultsObj(this.report.default_parameters)
    }
    let resp = await DataProvider.getDeviceList(['device_imei', 'device_name', 'device_code', 'device_id'])
    if (resp.success) {
      this.deviceList = resp.data
    } else {
      ErrorHelper.displayDataErrorToast(resp)
    }
    this.reportParamChange()
  },
  methods: {
    hideModal(e) {
      e.preventDefault()
      this.$bvModal.hide(this.modalId)
    },
    runReport () {
      let params = this.getReportParams()
      console.log('Report Params: ', params)
      this.$router.push({ path: '/reports/' + this.report.id, query: { ...params } })
    },
    getDefaultParametersObj () {
      /***
       * Return an Object which represents the Default Parameters set in the 'Default Parameters' tab
       * @type {{defaultDateMode: string, defaultStartDateValue: string, defaultDeviceSelectionValue: string, defaultEndDateValue: string, defaultEndDateType: string, defaultDeviceSelectionMode: string, defaultDateGroupingType: string, defaultStartDateType: string}}
       */
      let paramDefaultLookup = {
        defaultDateMode: 'default_date_mode',
        defaultStartDateType: 'default_start_date_type',
        defaultStartDateValue: 'default_start_date_value',
        defaultEndDateType: 'default_end_date_type',
        defaultEndDateValue: 'default_end_date_value',
        defaultDateGroupingType: 'default_date_grouping',
        defaultDeviceSelectionMode: 'default_device_mode',
        defaultDeviceSelectionValue: 'default_device_value'
      }
      let paramObj = {}

      for (let key in paramDefaultLookup) {
        console.log()
        if (this[key] !== null && (!Array.isArray(this[key]) || this[key].length > 0)) {
          paramObj[paramDefaultLookup[key]] = this[key]
        }
      }
      return paramObj
    },
    loadCurrentsFromDefaultsObj (defaultParameters) {
      /***
       * Set the 'Current' report settings to those specified by the provided parameters object.
       * @type {{default_end_date_value: string, default_device_value: string, default_date_grouping: string, default_device_mode: string, default_start_date_value: string, default_start_date_type: string, default_end_date_type: string, default_date_mode: string}}
       */
      let paramLookup = {
        default_date_mode: 'currentDateMode',
        default_start_date_type: 'currentStartDateType',
        default_start_date_value: 'currentStartDateValue',
        default_end_date_type: 'currentEndDateType',
        default_end_date_value: 'currentEndDateValue',
        default_date_grouping: 'currentDateGroupingType',
        default_device_mode: 'currentDeviceSelectionMode',
        default_device_value: 'currentDeviceSelectionValue'
      }

      for (let key in defaultParameters) {
        if (Object.hasOwn(paramLookup, key)) {
          this.$set(this, paramLookup[key], defaultParameters[key])
        } else {
          console.warn('Unknown Default Parameter Key: ', key)
        }
      }
    },
    loadDefaultsFromDefaultsObj (defaultParameters) {
      /***
       * Set the 'Default' report settings in the 'Default Parameters' tab to those specified by the provided
       * parameters Object.
       * @type {{default_end_date_value: string, defaultDeviceSelectionValue: string, default_start_date_value: string, default_start_date_type: string, default_end_date_type: string, defaultDeviceSelectionMode: string, default_date_mode: string, defaultDateGroupingType: string}}
       */
      let paramLookup = {
        default_date_mode: 'defaultDateMode',
        default_start_date_type: 'defaultStartDateType',
        default_start_date_value: 'defaultStartDateValue',
        default_end_date_type: 'defaultEndDateType',
        default_end_date_value: 'defaultEndDateValue',
        default_date_grouping: 'defaultDateGroupingType',
        default_device_mode: 'defaultDeviceSelectionMode',
        default_device_value: 'defaultDeviceSelectionValue'
      }

      for (let key in defaultParameters) {
        if (Object.hasOwn(paramLookup, key)) {
          this.$set(this, paramLookup[key], defaultParameters[key])
        } else {
          console.warn('Unknown Default Parameter Key: ', key)
        }
      }
    },
    requiresDeviceSelected () {
      /* Returns true if the current report requires a device to be selected before it can be run */
      if (!this.enableDeviceSelection) {
        return true
      }
      if (this.enableDeviceSelection === 'all') {
        return false
      }
      if (this.enableDeviceSelection === 'selection') {
        return !this.currentDeviceSelectionValue || this.currentDeviceSelectionValue.length === 0
      }
      if (this.enableDeviceSelection === 'single') {
        return !this.currentDeviceSelectionValue
      }
      return false
    },
    async saveDefaultParameters () {
      console.log(this.getDefaultParametersObj())
      let resp = await DataProvider.editReport(this.report.id, this.getDefaultParametersObj())
      if (resp.success) {
        AlertHelper.successToast('Default Report Parameters Updated', 'Report Update Success')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
      this.$emit('change')
      this.$bvModal.hide(this.modalId)
    },
    getCurrentReportParamsObj () {
      return {
        date_mode: this.currentDateMode,
        start_date_type: 'currentStartDateType',
        start_date_value: 'currentStartDateValue',
        default_end_date_type: 'currentEndDateType',
        default_end_date_value: 'currentEndDateValue',
        default_date_grouping: 'currentDateGroupingType',
        default_device_mode: 'currentDeviceSelectionMode',
        default_device_value: 'currentDeviceSelectionValue'
      }
    },
    getReportParams () {
      // 'null' value for relatedIds functions as 'all' (effectively not applying the filter)
      let relatedIds = null
      if (this.deviceSelectMode === 'multi' && this.currentDeviceSelectionMode === 'selection') {
        relatedIds = this.currentDeviceSelectionValue
      } else if (this.deviceSelectMode === 'single') {
        relatedIds = [this.currentDeviceSelectionValue]
      }

      if (this.currentDateMode === 'between-dates') {
        this.currentStartDateType = 'fixed-date'
        this.currentEndDateType = 'fixed-date'
      }

      return ReportHelper.getReportDatesParams(this.currentDateMode,
        this.currentStartDateType, this.currentStartDateValue, this.currentEndDateType, this.currentEndDateValue,
        this.currentDateGroupingType, this.currentTimezone, relatedIds, this.relatedType)
    },
    reportParamChange () {
      if (this.currentDateMode && this.currentDateGroupingType) {
        console.log('Report Parameters Changed: ', this.getReportParams())
        this.$emit('params-change', this.getReportParams())
      }
    },
    async saveReportProps ($event, reportData = null) {
      if ($event) {
        $event.preventDefault()
      }
      let result = await DataProvider.editReport(
        this.report.id, null, this.reportName, reportData, this.reportIcon, this.reportColor, this.isPublic,
        this.reportOwner)
      if (result.success) {
        AlertHelper.successToast('Report Name/Icon Changed', 'Save Success')
        this.report.name = this.reportName
        this.report.icon = this.reportIcon
        this.report.color = this.reportColor
        this.report.owner_id = this.reportOwner
        this.$emit('change')
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    },
    saveAdminOptions () {
      if (!Object.hasOwn(this.report.data, 'report_options')) {
        this.report.data.report_options = { device_selection_mode: null }
      }

      if (this.deviceSelectMode !== this.getReportOption('device_selection_mode')) {
        this.report.data.report_options.device_selection_mode = this.deviceSelectMode
      }
      if (this.relatedType !== this.getReportOption('related_type')) {
        this.report.data.report_options.related_type = this.relatedType
      }
      this.saveReportProps(null, this.report.data)
    },
    getReportOption (optionName) {
      if (Object.hasOwn(this.report.data, 'report_options') &&
        Object.hasOwn(this.report.data.report_options, optionName)) {
        return this.report.data.report_options[optionName]
      } else {
        return null
      }
    },
    setDeviceData (deviceData) {
      console.log('Got Device Data')
      console.log(deviceData)
      this.currentDeviceSelectionValue = deviceData['device_selection_value']
      console.log('Current Device Selection Value: ', this.currentDeviceSelectionValue)
    },
    setDeviceDefaultData (deviceData) {
      console.log('Got Device Data')
      console.log(deviceData)
      this.defaultDeviceSelectionValue = deviceData['device_selection_value']
      console.log('Default Device Selection Value: ', this.defaultDeviceSelectionValue)
    },
  },
  computed: {
    enableEdit () {
      if (this.report) {
        return (this.report.owner_id === this.userId || this.isAdmin)
      } else {
        return false
      }
    },
    enableDeviceSelection () {
      return this.getReportOption('device_selection_mode')
    },
    dateModeCurrentOptions () {
      return [{
        value: null,
        text: 'Select a Date Mode',
        help: 'This determines which dates will be included in the report'
      }].concat(this.dateModeOptions)
    },
    dateGroupingCurrentOptions () {
      return [{
        value: null,
        text: 'Select a Date Grouping',
        help: 'This determines how data will be grouped in the report.'
      }].concat(this.dateGroupingOptions)
    },
    dateModeDefaultOptions () {
      return [{
        value: null,
        text: 'No Default Dates',
        help: 'Dates will need to be manually selected when the report is run.'
      }].concat(this.dateModeOptions)
    },
    dateGroupingDefaultOptions () {
      return [{
        value: null,
        text: 'No Default Date Grouping',
        help: 'Date Grouping will need to be manually selected when the report is run.'
      }].concat(this.dateGroupingOptions)
    },
    deviceSelectionModeDefaultOptions () {
      return [{
        value: null,
        text: 'No Default Device Selection',
        help: 'Devices will need to be manually selected when the report is run.'
      }].concat(this.deviceSelectionModeOptions)
    },
    dateModeTooltip () {
      let prefix = 'Date Mode determines how the data for a report is selected. '
      let selectedMode = this.dateModeOptions.find(x => x.value === this.defaultDateMode)
      return `${prefix} ${selectedMode && Object.hasOwn(selectedMode, 'help') ? selectedMode.help : ''}`
    },
    dateGroupingModeTooltip () {
      let prefix = 'Determines how data is grouped. '
      let selectedMode = this.dateModeOptions.find(x => x.value === this.defaultDateMode)
      return `${prefix} ${selectedMode && Object.hasOwn(selectedMode, 'help') ? selectedMode.help : ''}`
    },
    deviceSelectionModeOptions () {
      if (this.deviceSelectMode === 'multi') {
        return [
          {
            value: 'all',
            text: 'All Devices'
          },
          {
            value: 'selection',
            text: 'Specific Devices'
          }
        ]
      } else {
        let deviceOptions = [
          {
            value: null,
            text: '- Select a Device -'
          }
        ]
        Object.keys(this.deviceList).forEach(deviceIMEI => {
          deviceOptions.push({
            text: this.deviceList[deviceIMEI].device_name,
            value: this.deviceList[deviceIMEI].device_id
          })
        })
        return deviceOptions
      }
    },
    defaultDateModeText () {
      /***
       * Returns a String that describes the result of the selected default Start and End Dates to help people
       * understand what the different options will result in.
       */
      let text = ''
      if (this.defaultStartDateType) {
        let defaultStartType = this.dateTypeOptions.find(x => x.value === this.defaultStartDateType)
        text += 'From ' + fillTemplate(defaultStartType.help, { 'dateValue': this.defaultStartDateValue })
      } else if (this.defaultEndDateType) {
        text += '<Select a Start Date Mode>'
      }
      if (this.defaultEndDateType) {
        let defaultEndType = this.dateTypeOptions.find(x => x.value === this.defaultEndDateType)
        text += ' To ' + fillTemplate(defaultEndType.help, { 'dateValue': this.defaultEndDateValue })
      } else if (this.defaultStartDateType) {
        text += ' to <Select an End Date Mode>'
      }
      return text
    },
    defaultDateError () {
      /***
       * Returns an error messages if the default date values specified are invalid in some way.
       */
      if (this.defaultStartDateType && this.defaultEndDateType) {
        let defaultStartType = this.dateTypeOptions.find(x => x.value === this.defaultStartDateType)
        let defaultEndType = this.dateTypeOptions.find(x => x.value === this.defaultEndDateType)
        // We can compare settings with a 'compareValue' assigned, as they are relative points in time.
        if (Object.hasOwn(defaultStartType, 'compareValue') && Object.hasOwn(defaultEndType, 'compareValue')) {
          return defaultEndType.compareValue > defaultStartType.compareValue ? 'End Date must be after Start Date.' : null
          // If BOTH of the dates are relative day numbers or fixed dates we can also compare the values reliably.
        } else if ((defaultStartType.value === 'relative-past-days' && defaultEndType.value === 'relative-past-days') ||
          (defaultStartType.value === 'fixed-date' && defaultEndType.value === 'fixed-date')) {
          return this.defaultEndDateValue > this.defaultStartDateValue ? 'End Date must be after Start Date.' : null
        }
      }
      return null
    },
    reportNameDirty () {
      return this.reportName !== this.report.name
    },
    reportIsPublicDirty () {
      return this.isPublic !== this.report.is_public
    },
    reportIconDirty () {
      console.log(this.reportIcon)
      return this.reportIcon !== this.report.icon
    },
    reportColorDirty () {
      return this.reportColor !== this.report.color
    },
    reportOwnerDirty () {
      return this.reportOwner !== this.report.owner_id
    },
    anyDirty() {
      return this.reportNameDirty || this.reportIsPublicDirty || this.reportColorDirty || this.reportIconDirty ||
          this.reportOwnerDirty
    },
    previewIconValue () {
      if (this.reportIcon) {
        return 'fa ' + this.reportIcon
      } else {
        return 'fa fa-signal'
      }
    },
    reportOwnerOptions () {
      console.log(this.report)
      let options = [
        {
          text: '<System>',
          value: 0
        }
      ]
      if (this.userId) {
        options.push({
          text: `User #${this.userId} (Me)`,
          value: this.userId
        })
      }
      console.log('Owner Options: ', options)
      return options
    },
    reportIconColor () {
      return this.reportColor || '#FFFFFF'
    },
    requiresDatesSelected () {
      /* Returns true if the current report requires dates to be selected before it can be run */
      if (this.currentDateMode === 'between-dates') {
        return !this.currentStartDateValue || !this.currentEndDateValue
      } else {
        return this.currentDateMode === null
      }
    },
    requiresAnyParametersToBeSelected () {
      /* Returns true if the current report requires any parameters to be selected before it can be run */
      return (
        !this.currentDateGroupingType &&
        !this.currentDateMode &&
        !this.requiresDeviceSelected &&
        !this.requiresDatesSelected
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.report-properties {
  display: flex;
  flex-direction: column;
}

.device-select {
  overflow: auto;
  max-height: 35vh;
}

.icon-preview {
  color: white;
  font-size: 2em;
  text-align: center;
  min-width: 1.6em;
  padding: 2px;
  background: $theme-color-background-2;
  margin-right: 1em;
}

</style>
