var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.enableDeviceSelection === "multi"
      ? _c(
          "div",
          { staticClass: "position-relative z-2" },
          [
            _c("label", { staticClass: "green-label mt-1" }, [
              _vm._v("Device Selection:")
            ]),
            _c(
              "b-input-group",
              [
                _c("b-select", {
                  staticClass: "text-input",
                  attrs: { options: _vm.deviceSelectionModeOptions },
                  on: { change: _vm.reportParamChange },
                  model: {
                    value: _vm.currentDeviceSelectionMode,
                    callback: function($$v) {
                      _vm.currentDeviceSelectionMode = $$v
                    },
                    expression: "currentDeviceSelectionMode"
                  }
                }),
                _c(
                  "b-input-group-append",
                  { staticClass: "align-items-center" },
                  [
                    _c("i", {
                      directives: [{ name: "b-toggle", rawName: "v-b-toggle" }],
                      staticClass: "fa fa-info-circle",
                      attrs: {
                        title:
                          "Determines which devices/vehicles will appear in the report"
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm.currentDeviceSelectionMode === "selection"
              ? _c(
                  "div",
                  { staticClass: "device-select" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          checked: _vm.deviceSelectionIsAll,
                          indeterminate: _vm.deviceSelectIntermediate,
                          "aria-describedby": "devices",
                          "aria-controls": "devices"
                        },
                        on: { change: _vm.toggleAllDeviceSelection }
                      },
                      [_vm._v(" All Devices ")]
                    ),
                    _c("b-form-checkbox-group", {
                      attrs: {
                        id: "devices",
                        options: _vm.deviceCheckboxGroupOptions,
                        name: "devices",
                        "aria-label": "Select Devices",
                        stacked: ""
                      },
                      on: { change: _vm.deviceCheckboxChange },
                      model: {
                        value: _vm.currentDeviceSelectionValue,
                        callback: function($$v) {
                          _vm.currentDeviceSelectionValue = $$v
                        },
                        expression: "currentDeviceSelectionValue"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.enableDeviceSelection === "single"
      ? _c(
          "div",
          { staticClass: "position-relative z-2" },
          [
            _c("label", { staticClass: "green-label mt-1" }, [
              _vm._v("Select Device:")
            ]),
            _c(
              "b-input-group",
              [
                _c("b-select", {
                  staticClass: "text-input",
                  attrs: {
                    required: "",
                    options: _vm.deviceSelectionModeOptions
                  },
                  on: {
                    change: function($event) {
                      return _vm.reportParamChange()
                    }
                  },
                  model: {
                    value: _vm.currentDeviceSelectionValue,
                    callback: function($$v) {
                      _vm.currentDeviceSelectionValue = $$v
                    },
                    expression: "currentDeviceSelectionValue"
                  }
                }),
                _c(
                  "b-input-group-append",
                  { staticClass: "align-items-center" },
                  [
                    _c("i", {
                      directives: [{ name: "b-toggle", rawName: "v-b-toggle" }],
                      staticClass: "fa fa-info-circle",
                      attrs: {
                        title:
                          "Determines which device/vehicle will appear in the report"
                      }
                    })
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }