<template>
  <div>
    <div class="position-relative z-2" v-if="enableDeviceSelection==='multi'">
      <label class="green-label mt-1">Device Selection:</label>
      <b-input-group>
        <b-select class="text-input" @change="reportParamChange"
                  :options="deviceSelectionModeOptions" v-model="currentDeviceSelectionMode"/>
        <b-input-group-append class="align-items-center">
          <i class="fa fa-info-circle" v-b-toggle
             title="Determines which devices/vehicles will appear in the report"></i>
        </b-input-group-append>
      </b-input-group>

      <div class="device-select" v-if="currentDeviceSelectionMode==='selection'">
        <b-form-checkbox
          :checked="deviceSelectionIsAll"
          :indeterminate="deviceSelectIntermediate"
          aria-describedby="devices"
          aria-controls="devices"
          @change="toggleAllDeviceSelection"
        >
          All Devices
        </b-form-checkbox>
        <b-form-checkbox-group
          id="devices"
          v-model="currentDeviceSelectionValue"
          :options="deviceCheckboxGroupOptions"
          name="devices"
          aria-label="Select Devices"
          stacked
          @change="deviceCheckboxChange"
        ></b-form-checkbox-group>
      </div>
    </div>
    <div class="position-relative z-2" v-if="enableDeviceSelection==='single'">
      <label class="green-label mt-1">Select Device:</label>
      <b-input-group>
        <b-select class="text-input" @change="reportParamChange()" required
                  :options="deviceSelectionModeOptions" v-model="currentDeviceSelectionValue"/>
        <b-input-group-append class="align-items-center">
          <i class="fa fa-info-circle" v-b-toggle
             title="Determines which device/vehicle will appear in the report"></i>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>
<script>

export default {
  name: 'device-selection',
  props: {
    enableDeviceSelection: String,
    currentDeviceSelectionMode: [String, Number],
    defaultDeviceSelectionValue: [String, Array, Number],
    deviceSelectionModeOptions: [String, Array],
    deviceList: [Array, Object],
  },
  data () {
    return {
      currentDeviceSelectionValue: null,
    }
  },
  methods: {
    getDeviceParams () {
      // Function returns an Array, which must contain the key 'device_selection_value'
      if (this.enableDeviceSelection === 'multi') {
        if (this.currentDeviceSelectionMode === 'all') {
          return {
            device_selection_value: 'all'
          }
        }
      }
      return {
        device_selection_value: this.currentDeviceSelectionValue
      }
    },
    reportParamChange () {
      console.log('Device Selection Changed')
      this.$emit('device-change', this.getDeviceParams())
    },
    toggleAllDeviceSelection (newState) {
      console.log(newState, this.deviceList)
      this.currentDeviceSelectionValue = newState ? Object.keys(this.deviceList) : []
    },
    deviceCheckboxChange (selectedDevices) {
      console.log(selectedDevices)
      this.currentDeviceSelectionValue = selectedDevices
      this.reportParamChange()
    },
  },
  computed: {
    deviceSelectIntermediate () {
      // Determines if the 'All Devices' checkbox should be displayed as intermediate
      if (Array.isArray(this.currentDeviceSelectionValue)) {
        return this.currentDeviceSelectionValue.length > 0 &&
          this.currentDeviceSelectionValue.length < Object.keys(this.deviceList).length
      } else {
        return false
      }
    },
    deviceSelectionIsAll () {
      if (Array.isArray(this.currentDeviceSelectionValue)) {
        return this.currentDeviceSelectionValue.length === Object.keys(this.deviceList).length
      } else {
        return false
      }
    },
    deviceCheckboxGroupOptions () {
      if (this.deviceList) {
        return Object.keys(this.deviceList).map(deviceImei => {
          return {
            value: this.deviceList[deviceImei].device_id,
            text: this.deviceList[deviceImei].device_name,
            disabled: false
          }
        })
      } else {
        return []
      }
    },
  },
  mounted () {
    // Copy value from defaultDeviceSelectionValue
    console.log('Device Selection Mounted', this.defaultDeviceSelectionValue)
    this.currentDeviceSelectionValue = this.defaultDeviceSelectionValue
  },
  // watch: {
  //   currentDeviceSelectionValue(newValue) {
  //     console.log('New Device Selection Value', newValue)
  //     this.currentDeviceSelectionValue = newValue
  //   }
  // }
}
</script>
<style scoped lang="scss">
@import '@/variables';

.device-select {
  overflow: auto;
  max-height: 35vh;
}

</style>
